export default (props) => {
    return {
        base: {
            entities: "لاگ های خطا",
            entity: "لاگ خطا",
            module: "error_logs",
            path: "/error_logs_instance",
            model: {
                source_id: {
                    title: "منبع",
                    type: "foreign",
                    foreign: {
                        module: "sources",
                        path: "/sources",
                        field: "id",
                        result: [{name: "name"}]
                    }
                },
                type: {title: "نوع خطا"},
                page_url: {title: "آدرس"},
                selector: {title: "سلکتور"},
                error: {title: "خطا"},
                date: {title: "تاریخ", type: "timeago"}
            }
        },
        list: {
            page: props.page,
            default_order: "date",
            default_conditions: props.default_conditions,
            fields: [
                {name: "source_id"},
                {name: "type"},
                {
                    name: "page_url",
                    type: "function",
                    value: {
                        func: props.showLink,
                        params: ["page_url"]
                    }
                },
                {name: "selector"},
                {name: "error", max_length: 10},
                {name: "date", alias: "dateago", style: {direction: "ltr"}},
            ],
            search: [
                {
                    component_type: "select",
                    type: "field",
                    name: "source_id",
                    value: "source_id",
                    field: "source_id",
                    source_data: props.sources,
                    placeholder: "منبع",
                    search_type: "exact",
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "type",
                    value: "type",
                    field: "type",
                    placeholder: "نوع خطا",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "page_url",
                    value: "page_url",
                    field: "page_url",
                    placeholder: "آدرس",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "error",
                    value: "error",
                    field: "error",
                    placeholder: "خطا",
                    search_type: "regex",
                    regex_type: "middle"
                },
            ],
            operations: []
        },
    };
};