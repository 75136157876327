export default (props) => {
    return {
        base: {
            entities: "منابع",
            entity: "منبع",
            module: "sources",
            path: "/sources",
            confirm_field: "enabled",
            model: {
                name: {title: "نام"},
                url: {title: "آدرس"},
                logo: {title: "لوگو", type: "image"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "logo"},
                {name: "name"},
                {name: "url"}
            ],
            operations: ["add", "edit", "remove", "confirm"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "logo"},
                {name: "name"},
                {name: "url"}
            ]
        }
    };
};