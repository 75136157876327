import React, { Component } from 'react';
import {Alert, Button} from "react-bootstrap";
import xhr from "../components/xhr";

const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class Home extends Component {
    state = {
        loading: false,
        message: null
    };

    deleteDuplicates = () => {
        new xhr(this, 'delete_duplicate').GetManyPure(response => {
            if(response.status){
                this.setState({message: <Alert variant="success">عملیات با موفقیت انجام شد</Alert>});
            }else{
                this.setState({message: <Alert variant="danger">عملیات با خطا مواجه شد</Alert>});
            }
        });
    };

    send = type => {
        this.setState({loading: true});
        new xhr(this, 'run', 'type=' + type).GetManyPure(response => {
            console.log(response);
            this.setState({loading: false});
            if(response.status){

            }else{

            }
        });
    };

    render(){
        let {loading, message} = this.state;
        let BU = localStorage.getItem('BASE_URL');
        return (
            <div>
                <h3 style={{marginBottom: "10px"}}>میز خبر</h3>
                {message}
                {BU ?
                    BU === 'http://158.58.190.53:8000/v1' || BU === 'http://5.63.9.73:8000/v1' || BU === 'http://localhost:8000/v1' || BU.indexOf('192.168.') > -1 ?
                        <div>
                            <div style={{marginBottom: "10px"}}><Button onClick={() => this.send('link')}>{loading ? loadingGif : 'اجرای موتور لینک'}</Button></div>
                            <div style={{marginBottom: "10px"}}><Button onClick={() => this.send('text')}>{loading ? loadingGif : 'اجرای موتور متن'}</Button></div>
                            <div style={{marginBottom: "10px"}}><Button onClick={() => this.send('drop_mongo_news')}>{loading ? loadingGif : 'حذف اخبار مونگو'}</Button></div>
                            <div style={{marginBottom: "10px"}}><Button onClick={() => this.send('drop_elastic_news')}>{loading ? loadingGif : 'حذف اخبار الستیک'}</Button></div>
                            <div style={{marginBottom: "10px"}}><Button onClick={() => this.send('drop_engine_instances')}>{loading ? loadingGif : 'حذف گزارشات اجرای موتور'}</Button></div>
                            <div style={{marginBottom: "10px"}}><Button onClick={() => this.send('drop_error_logs')}>{loading ? loadingGif : 'حذف خطاها'}</Button></div>
                            <div style={{marginBottom: "10px"}}><Button onClick={() => this.send('drop_all')}>{loading ? loadingGif : 'حذف همه'}</Button></div>
                        </div>
                        :
                        <div/>
                    :
                    <div/>
                }
                <Button onClick={this.deleteDuplicates}>حذف اخبار تکراری</Button>
            </div>
        );
    }
}