import React, { Component } from 'react';
import List from "../components/List";
import entityModel from "../models/Users";
import Item from "../components/Item";

export default class Users extends Component {
    state = {
        users: {}
    };

    componentDidMount(){
        this.setState({
            users : entityModel({
                page: this.props.match.params.page,
                id: this.props.match.params.id,
                show_sessions: this.show_sessions,
                ROLES: global.config.ROLES,
                lockIcon: <span className="glyphicon glyphicon-lock"/>
            })
        });
    }

    show_sessions = (args) => {
        window.open("/sessions/" + args.id);
    };

    render(){
        let {users} = this.state;
        let {pathname} = this.props.location;
        return (
            <div>
                {users.base ?
                    pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                        <Item base={users.base} data={users.item}/>
                        :
                        <List base={users.base} data={users.list}/>
                    : ''}
            </div>
        );
    }
}