import React, { Component } from 'react';
import List from "../components/List";
import entityModel from "../models/EngineInstances";
import {Link} from 'react-router-dom';

export default class EngineInstances extends Component {
    state = {
        entities: {}
    };

    componentDidMount(){
        this.setState({
            entities : entityModel({
                page: this.props.match.params.page,
                id: this.props.match.params.id,
                showErrors: this.showErrors
            })
        });
    }

    showErrors = args => <Link to={"/error_logs_instance/" + args[0]}>{args[1]}</Link>;

    render(){
        let {entities} = this.state;
        return (
            <div>
                {entities.base && <List base={entities.base} data={entities.list}/>}
            </div>
        );
    }
}