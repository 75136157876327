import React, { Component } from 'react';
import List from "../components/List";
import Item from "../components/Item";
import entityModel from "../models/News";
import {Modal, Button} from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import xhr from '../components/xhr';

export default class News extends Component {
    state = {
        entities: {},
        show: false,
        title: '',
        body: '',
        sources: null,
        categories: null
    };

    setEntity = () => {
        let {sources, categories} = this.state;
        if(!sources || !categories) return;
        this.setState({
            entities : entityModel({
                page: this.props.match.params.page,
                id: this.props.match.params.id,
                link: this.link,
                button: this.button,
                sources,
                categories
            })
        });
    };

    componentDidMount(){
        new xhr(this, 'sources', '', -1).GetManyPure(response => {
            if(response.status){
                let sources = response.data.data.list.map(src => {
                    return {key: src.id, value: src.name};
                });
                this.setState({sources}, this.setEntity);
            }
        });
        new xhr(this, 'categories', '', -1).GetManyPure(response => {
            if(response.status){
                let categories = response.data.data.list.map(cat => {
                    return {key: cat.id, value: cat.name};
                });
                this.setState({categories}, this.setEntity);
            }
        });
    }

    link = args => {
        return <a href={args[0]} target="_blank">{args[1]}</a>;
    };

    button = args => {
        return <Button variant="info" onClick={() => this.showModal(args[1], args[0])}>{args[1]}</Button>;
    };

    showModal = (title, body) => {
        this.setState({
            title,
            body: ReactHtmlParser(body),
            show: true
        });
    };

    hide = () => {
        this.setState({
            show: false,
            title: '',
            body: ''
        });
    };

    render(){
        let {entities, show, title, body} = this.state;
        let {pathname} = this.props.location;
        return (
            <div>
                <Modal show={show} onHide={this.hide}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {body}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={this.hide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                {entities.base ?
                    pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                        <Item base={entities.base} data={entities.item}/>
                        :
                        <List base={entities.base} data={entities.list}/>
                    : ''}
            </div>
        );
    }
}