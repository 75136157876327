export default (props) => {
    return {
        base: {
            entities: "شهرها",
            entity: "شهر",
            module: "cities",
            path: "/cities",
            model: {
                country_id: {
                    title: "کشور",
                    type: "foreign",
                    foreign: {
                        module: "countries",
                        path: "/countries",
                        field: "id",
                        result: [{name: "name"}]
                    }
                },
                name: {title: "نام"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "name"},
                {name: "country_id"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "name"},
                {name: "country_id", type: "select_data"},
            ]
        }
    };
};