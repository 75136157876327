import React, { Component } from 'react';
import List from "../components/List";
import Item from "../components/Item";
import entityModel from "../models/ErrorLogsInstance";
import xhr from '../components/xhr';

export default class ErrorLogsInstance extends Component {
    state = {
        entities: {},
        sources: []
    };
    instanceId = null;

    componentDidMount(){
        this.instanceId = this.props.match.params.instance_id;
        let default_conditions = null;
        if(this.instanceId){
            default_conditions = {engine_instance_id: this.instanceId};
        }
        new xhr(this, 'sources', '', -1).GetManyPure(response => {
            if (response.status) {
                let sources = response.data.data.list.map(src => {
                    return {key: src.id, value: src.name};
                });
                this.setState({sources}, () => {
                    this.setState({
                        entities : entityModel({
                            page: this.props.match.params.page,
                            id: this.props.match.params.id,
                            showLink: this.showLink,
                            default_conditions,
                            sources: this.state.sources
                        })
                    });
                });
            }
        });
    }

    showLink = (args) => {
        return <a href={args[0]} target="_blank">{args[0]}</a>;
    };

    render(){
        let {entities} = this.state;
        let {pathname} = this.props.location;
        return (
            <div>
                {entities.base ?
                    pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                        <Item base={entities.base} data={entities.item}/>
                        :
                        <List base={entities.base} data={entities.list} params={{instance_id: this.instanceId}}/>
                    : ''}
            </div>
        );
    }
}