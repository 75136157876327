import React, { Component } from 'react';
import List from "../components/List";
import Item from "../components/Item";
import entityModel from "../models/Cities";

export default class Cities extends Component {
    state = {
        entities: {}
    };

    componentDidMount(){
        this.setState({
            entities : entityModel({
                page: this.props.match.params.page,
                id: this.props.match.params.id,
            })
        });
    }

    render(){
        let {entities} = this.state;
        let {pathname} = this.props.location;
        return (
            <div>
                {entities.base ?
                    pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                        <Item base={entities.base} data={entities.item}/>
                        :
                        <List base={entities.base} data={entities.list}/>
                    : ''}
            </div>
        );
    }
}