export default (props) => {
    return {
        base: {
            entities: "لینک های منابع",
            entity: "لینک منبع",
            module: "source_links",
            path: "/source_links",
            confirm_field: "enabled",
            model: {
                source_id: {
                    title: "منبع",
                    type: "foreign",
                    foreign: {
                        module: "sources",
                        path: "/sources",
                        field: "id",
                        result: [{name: "name"}]
                    }
                },
                category_id: {
                    title: "دسته بندی",
                    type: "foreign",
                    foreign: {
                        module: "categories",
                        path: "/categories",
                        field: "id",
                        result: [{name: "name"}]
                    }
                },
                base_url: {title: "آدرس پایه"},
                url: {title: "آدرس"},
                box: {title: "باکس"},
                link: {title: "لینک"},
                summary: {title: "خلاصه"},
                date: {title: "تاریخ"},
                title: {title: "عنوان"},
                image: {title: "تصویر"},
                text: {title: "متن خبر"},
                exclude: {title: "حذف"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "source_id"},
                {
                    name: "base_url",
                    type: "function",
                    value: {
                        func: props.showLink,
                        params: ["base_url"]
                    }
                },
                {
                    name: "url",
                    type: "function",
                    value: {
                        func: props.showLink,
                        params: ["url"]
                    }
                },
                {name: "category_id"},
                {name: "box", max_length: 10, style: {direction: "ltr", textAlign: "left"}},
                {name: "link", max_length: 10, style: {direction: "ltr", textAlign: "left"}},
                {name: "summary", max_length: 10, style: {direction: "ltr", textAlign: "left"}},
                {name: "date", max_length: 10, style: {direction: "ltr", textAlign: "left"}},
                {name: "title", max_length: 10, style: {direction: "ltr", textAlign: "left"}},
                {name: "image", max_length: 10, style: {direction: "ltr", textAlign: "left"}},
                {name: "text", max_length: 10, style: {direction: "ltr", textAlign: "left"}},
            ],
            search: [
                {
                    component_type: "select",
                    type: "field",
                    name: "category_id",
                    value: "category_id",
                    field: "category_id",
                    placeholder: "دسته بندی",
                    search_type: "exact",
                    source_data: props.categories
                },
                {
                    component_type: "select",
                    type: "field",
                    name: "source_id",
                    value: "source_id",
                    field: "source_id",
                    placeholder: "منبع",
                    search_type: "exact",
                    source_data: props.sources
                }
            ],
            custom_operations: [
                {
                    'class': 'primary',
                    caption: props.testIcon,
                    title: "تست",
                    click: {
                        func: props.testSource,
                        params: ["id"]
                    }
                },
                {
                    'class': 'warning',
                    caption: props.duplicateIcon,
                    title: "داپلیکیت",
                    click: {
                        func: props.duplicate,
                        params: ["id"]
                    }
                }
            ],
            operations_style: {width: "220px"},
            operations: ["add", "edit", "remove", "confirm"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "source_id", type: "select_data"},
                {name: "base_url", style: {direction: "ltr"}},
                {name: "url", style: {direction: "ltr"}},
                {name: "category_id", type: "select_data"},
                {name: "box", style: {direction: "ltr"}},
                {name: "link", style: {direction: "ltr"}},
                {name: "summary", style: {direction: "ltr"}},
                {name: "date", style: {direction: "ltr"}},
                {name: "title", style: {direction: "ltr"}},
                {name: "image", style: {direction: "ltr"}},
                {name: "text", style: {direction: "ltr"}},
                {name: "exclude", type: "multiple_items", style: {direction: "ltr"}}
            ]
        }
    };
};