import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';
import Header from "./pages/parts/Header";
import Sidebar from "./pages/parts/Sidebar";
import Login from "./pages/Login";
import PrivateRoute from './components/PrivateRoute';
import Users from "./pages/Users";
import Home from "./pages/Home";
import Countries from "./pages/Countries";
import Cities from "./pages/Cities";
import {Container, Row, Col} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import SourceLinks from "./pages/SourceLinks";
import TestCrawl from "./pages/TestCrawl";
import ErrorLogs from "./pages/ErrorLogs";
import News from "./pages/News";
import Sources from "./pages/Sources";
import EngineInstances from "./pages/EngineInstances";
import ErrorLogsInstance from "./pages/ErrorLogsInstance";
import Categories from "./pages/Categories";
import PremiumPackages from "./pages/PremiumPackages";
import DiscountCodes from "./pages/DiscountCodes";

export default class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            isAuthenticated: true
        };
        this.handleChangeLang = this.handleChangeLang.bind(this);
    }

    componentWillMount(){
        global.config.TOKEN = localStorage.getItem('token');
        if(!global.config.TOKEN){
            this.setState({isAuthenticated: false});
        }
        global.config.LOCALE = localStorage.getItem('locale');
        if(!global.config.LOCALE){
            global.config.LOCALE = "en";
        }
    }

    handleLogout(){
        localStorage.removeItem('token');
        global.config.TOKEN = '';
        window.location.reload();
    }

    handleChangeLang(lang){
        localStorage.setItem('locale', lang);
        window.location.reload();
    }

    render(){
        return (
            <div style={{width: "100%"}}>
                <Header changeLang={this.handleChangeLang} logout={() => this.handleLogout.bind(this)} auth={this.state.isAuthenticated}/>
                <Container fluid={true}>
                    <Row>
                        {this.state.isAuthenticated &&
                        <Col md={2}>
                            <Sidebar/>
                        </Col>
                        }
                        <Col md={this.state.isAuthenticated ? 10 : 12}>
                            <Switch>
								<PrivateRoute exact path="/" component={Home} auth={this.state.isAuthenticated}/>
                                <Route exact path="/login" component={Login} />

                                <PrivateRoute path="/sources/add" component={Sources} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/sources/edit/:id" component={Sources} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/sources/:page?" component={Sources} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/source_links/add" component={SourceLinks} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/source_links/edit/:id" component={SourceLinks} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/source_links/:page?" component={SourceLinks} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/users/add" component={Users} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/users/edit/:id" component={Users} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/users/:page?" component={Users} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/countries/add" component={Countries} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/countries/edit/:id" component={Countries} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/countries/:page?" component={Countries} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/cities/add" component={Cities} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/cities/edit/:id" component={Cities} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/cities/:page?" component={Cities} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/news/add" component={News} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/news/edit/:id" component={News} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/news/:page?" component={News} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/categories/add" component={Categories} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/categories/edit/:id" component={Categories} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/categories/:page?" component={Categories} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/premium_packages/add" component={PremiumPackages} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/premium_packages/edit/:id" component={PremiumPackages} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/premium_packages/:page?" component={PremiumPackages} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/discount_codes/add" component={DiscountCodes} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/discount_codes/edit/:id" component={DiscountCodes} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/discount_codes/:page?" component={DiscountCodes} auth={this.state.isAuthenticated}/>

                                <PrivateRoute exact path="/engine_instances/:page?" component={EngineInstances} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/test_crawl/:source/:page?" component={TestCrawl} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/error_logs/:page?" component={ErrorLogs} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/error_logs_instance/:instance_id/:page?" component={ErrorLogsInstance} auth={this.state.isAuthenticated}/>
                            </Switch>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}