import React, { Component } from 'react';
import {Accordion, Card, Button} from "react-bootstrap";

export default class Sidebar extends Component {
    render(){
        let id = 1;
        let links = [
            {
                id: id++,
                title: "اطلاعات پایه",
                options: [
                    {id: id++, module: "sources", title: "منابع", path: "/sources"},
                    {id: id++, module: "source_links", title: "لینک های منابع", path: "/source_links"},
                    {id: id++, module: "categories", title: "دسته بندی ها", path: "/categories"},
                    {id: id++, module: "premium_packages", title: "پکیج ها", path: "/premium_packages"},
                    {id: id++, module: "search", title: "اخبار", path: "/news"},
                    {id: id++, module: "discount_codes", title: "تخفیف ها", path: "/discount_codes"},
                    {id: id++, module: "engine_instances", title: "اجراهای موتور", path: "/engine_instances"},
                    {id: id++, module: "error_logs", title: "لاگ های خطا", path: "/error_logs"},
                ]
            },
        ];
        return (
            <div className="sidebar">
                <Accordion defaultActiveKey="0">
                    {links.map(link =>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    {link.title}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <ul className="my-nav">
                                        {link.options.map(option => <li><a href={option.path}>{option.title}</a></li>)}
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
                </Accordion>
            </div>
        );
    }
}