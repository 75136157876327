export default (props) => {
    return {
        base: {
            entities: "تست اخبار",
            entity: "تست اخبار",
            module: "test_crawl",
            path: "/test_crawl",
            model: {
                link: {title: "لینک"},
                summary: {title: "خلاصه"},
                date: {title: "تاریخ"},
                title: {title: "عنوان"},
                image: {title: "تصویر", type: "image"},
            }
        },
        list: {
            page: props.page,
            request_query_string: `source_id=${props.source}`,
            params: ["source"],
            fields: [
                {name: "image"},
                {name: "title"},
                {name: "summary"},
                {name: "link"},
                {name: "date"},
            ],
            custom_operations: [
                {
                    'class': 'primary',
                    caption: "تست",
                    click: {
                        func: props.test,
                        params: ["url"]
                    }
                }
            ],
            operations: ["remove"]
        }
    };
};