import React, { Component } from 'react';
import List from "../components/List";
import Item from "../components/Item";
import entityModel from "../models/ErrorLogs";
import xhr from '../components/xhr';
import {Button} from "react-bootstrap";

export default class ErrorLogs extends Component {
    state = {
        entities: {},
        sources: []
    };

    componentDidMount(){
        new xhr(this, 'sources', '', -1).GetManyPure(response => {
            if (response.status) {
                let sources = response.data.data.list.map(src => {
                    return {key: src.id, value: src.name};
                });
                this.setState({sources}, () => {
                    this.setState({
                        entities: entityModel({
                            page: this.props.match.params.page,
                            id: this.props.match.params.id,
                            showLink: this.showLink,
                            showTest: this.showTest,
                            sources: this.state.sources
                        })
                    });
                });
            }
        });
    }

    showLink = (args) => {
        let max_len = 50;
        return <a href={args[0]} target="_blank">{args[0].length > max_len ? args[0].substr(0, max_len) + '...' : args[0]}</a>;
    };

    showTest = args => {
        return <Button target="_blank" href={`/test_crawl/${args[0]}`}>تست</Button>;
    };

    render(){
        let {entities} = this.state;
        let {pathname} = this.props.location;
        return (
            <div>
                {entities.base ?
                    pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                        <Item base={entities.base} data={entities.item}/>
                        :
                        <List base={entities.base} data={entities.list}/>
                    : ''}
            </div>
        );
    }
}