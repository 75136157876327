import React, { Component } from 'react';
import List from "../components/List";
import Item from "../components/Item";
import entityModel from "../models/SourceLinks";
import xhr from "../components/xhr";
import {Modal} from 'react-bootstrap';

export default class SourceLinks extends Component {
    state = {
        entities: {},
        show: false,
        categories: null,
        sources: null
    };
    listRef = React.createRef();

    setEntity = () => {
        let {categories, sources} = this.state;
        if(!categories || !sources) return;

        this.setState({
            entities : entityModel({
                page: this.props.match.params.page,
                id: this.props.match.params.id,
                testSource: this.testSource,
                showLink: this.showLink,
                duplicateIcon: <span className="glyphicon glyphicon-duplicate"/>,
                testIcon: <span className="glyphicon glyphicon-play"/>,
                duplicate: this.duplicate,
                categories,
                sources
            })
        });
    };

    componentDidMount(){
        new xhr(this, 'categories', '', -1).GetManyPure(response => {
            if(response.status){
                let categories = response.data.data.list.map(cat => {
                    return {key: cat.id, value: cat.name};
                });
                this.setState({categories}, this.setEntity);
            }
        });
        new xhr(this, 'sources', '', -1).GetManyPure(response => {
            if(response.status){
                let sources = response.data.data.list.map(src => {
                    return {key: src.id, value: src.name};
                });
                this.setState({sources}, this.setEntity);
            }
        });
    }

    testSource = args => {
        window.open("/test_crawl/" + args.id);
    };

    duplicate = args => {
        this.setState({show: true});
        new xhr(this, 'source_links', args.id).GetOne(item => {
            if(item){
                console.log(item);
                delete item['id'];
                new xhr(this, 'source_links', item).Post(response => {
                    if(response.status){
                        this.listRef.current.getRows();
                    }else{
                        console.log(response.note);
                    }
                    this.setState({show: false});
                });
            }
        });
    };

    showLink = (args) => {
        let show_address = args[0];
        if(show_address.length > 20){
            show_address = show_address.substr(0, 20) + "...";
        }
        return <a href={args[0]} target="_blank">{show_address}</a>;
    };

    handleHide = () => this.setState({show: false});

    render(){
        let {entities, show} = this.state;
        let {pathname} = this.props.location;
        return (
            <div>
                <Modal show={show} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>کپی کردن</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>لطفاً کمی صبر کنید...</p>
                    </Modal.Body>
                </Modal>
                {entities.base ?
                    pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                        <Item base={entities.base} data={entities.item}/>
                        :
                        <List ref={this.listRef} base={entities.base} data={entities.list}/>
                    : ''}
            </div>
        );
    }
}