export default (props) => {
    return {
        base: {
            entities: "تخفیف ها",
            entity: "تخفیف",
            module: "discount_codes",
            path: "/discount_codes",
            model: {
                code: {title: "کد"},
                percent: {title: "درصد"},
                due_date: {title: "تاریخ", type: "datetime"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "code"},
                {name: "percent"},
                {name: "due_date", type: "date", alias: "due_date", style: {direction: "ltr"}}
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "code"},
                {name: "percent"},
                {name: "due_date"}
            ]
        }
    };
};