export default (props) => {
    return {
        base: {
            entities: "پکیج ها",
            entity: "پکیج",
            module: "premium_packages",
            path: "/premium_packages",
            model: {
                title: {title: "عنوان"},
                days: {title: "تعداد روز"},
                price: {title: "قیمت"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "title"},
                {name: "days"},
                {name: "price"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "title"},
                {name: "days"},
                {name: "price"},
            ]
        }
    };
};