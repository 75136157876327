import React, { Component } from 'react';
import {Form, Button, Alert} from 'react-bootstrap';
import xhr from "../components/xhr";

export default class Login extends Component {
    constructor(props){
        super(props);
        let BU = "https://newsapi.onmiz.info/v1";
        if(window.location.hostname === "localhost"){
            BU = "http://192.168.1.100:8000/v1";
        }
        this.state = {
            item: {
                code: '+98',
                mobile: '',
                password: '',
                version: '1.0.9',
                BASE_URL: BU
            },
            submitLoading: false
        };
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        let target = e.target;
        let item = this.state.item;
        item[target.name] = target.value;
        if(target.name === "server"){
            item.BASE_URL = target.value;
        }
        this.setState({item});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({submitLoading: true});
        localStorage.setItem('BASE_URL', this.state.item.BASE_URL);
        global.config.BASE_URL = this.state.item.BASE_URL;
        let data = this.state.item;
        new xhr(this, 'login', data).Post(response => {
            this.setState({submitLoading: false});
            if(response.status){
                localStorage.setItem('token', response.token);
                global.config.TOKEN = response.token;
                window.location = "/";
            }else{
                this.setState({message: <Alert variant="danger">{response.note}</Alert>});
            }
        });
    }

    render(){
        const {item, message, submitLoading} = this.state;
        const developServers = [
            "newshub.onmiz.net",
            "newshub.onmiz.org",
            "localhost"
        ];
        return (
            <div className="login-box">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    {(developServers.indexOf(window.location.hostname) > -1) &&
                    <span>
                        <Form.Group>
                            <Form.Label>سرور</Form.Label>
                            <Form.Control
                                as="select"
                                name="server"
                                value={item.server}
                                onChange={this.handleChange}
                            >
                                <option value="http://192.168.1.100:8000/v1">پایین</option>
                                <option value="https://newsapi.testpad.ir/v1">تست</option>
                                <option value="https://newsapi.onmiz.info/v1">بالا</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                name="BASE_URL"
                                className="ltr"
                                value={item.BASE_URL}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        </span>
                    }
                    <Form.Group>
                        <Form.Label>نام کاربری</Form.Label>
                        <Form.Control
                            type="text"
                            name="mobile"
                            value={item.mobile}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>رمز عبور</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={item.password}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Button type="submit" bsStyle="primary" disabled={submitLoading}>{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ورود'}</Button>
                </form>
            </div>
        );
    }
}