import React, { Component } from 'react';
import {Navbar, Nav} from "react-bootstrap";

export default class Header extends Component {
    render(){
        const {auth} = this.props;
        return (
            <Navbar collapseOnSelect expand="lg" variant="dark" bsPrefix="black navbar">
                <Navbar.Brand href="/">میز خبر</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {/*<Nav.Link href="/">سایت</Nav.Link>*/}
                    </Nav>
                    <Nav>
                        {auth ? 
							<Nav.Link eventKey={6} onClick={this.props.logout()}>خروج</Nav.Link> 
							: 
							<Nav.Link eventKey={6} href="/login">ورود</Nav.Link>
						}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}