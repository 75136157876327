export default (props) => {
    return {
        base: {
            entities: "اجراهای موتور",
            entity: "اجرای موتور",
            module: "engine_instances",
            path: "/engine_instances",
            model: {
                type: {title: "نوع"},
                duration: {title: "مدت اجرا"},
                source_links: {title: "تعداد منابع"},
                errors: {title: "تعداد خطاها"},
                new_contents: {title: "تعداد مطالب"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "type"},
                {name: "duration", type: "round", decimal: 1},
                {name: "source_links"},
                {name: "new_contents"},
                {
                    name: "errors",
                    type: "function",
                    value: {
                        func: props.showErrors,
                        params: ["id", "errors"]
                    }
                },
                {name: "start_date", type: "date", alias: "date", title: "تاریخ شروع"},
                {name: "start_date", type: "time", alias: "time", title: "زمان شروع"},
                {name: "start_date", title: "زمان نسبی شروع", type: "timeago", alias: "dateago", style: {direction: "ltr"}},
            ],
            search: [
                {
                    component_type: "select",
                    type: "field",
                    name: "type",
                    value: "type",
                    field: "type",
                    placeholder: "نوع را انتخاب کنید",
                    source_data: [
                        {key: "link", value: "لینک"},
                        {key: "text", value: "متن"}
                    ],
                    search_type: "exact"
                },
                {
                    component_type: "between_dates",
                    type: "field",
                    name: "start_date",
                    value: "start_date",
                    field: "start_date",
                    title: "بازه تاریخی",
                },
            ],
            operations: []
        },
    };
};