export default (props) => {
    return {
        base: {
            entities: "اخبار",
            entity: "خبر",
            module: "search",
            path: "/news",
            model: {
                source_id: {
                    title: "منبع",
                    type: "foreign",
                    foreign: {
                        module: "sources",
                        path: "/sources",
                        field: "id",
                        result: [{name: "name"}]
                    }
                },
                url: {title: "لینک"},
                url_hash: {title: "هش لینک"},
                title: {title: "عنوان"},
                summary: {title: "خلاصه"},
                date: {title: "تاریخ"},
                source_url: {title: "لینک منبع"},
                status: {
                    title: "وضعیت",
                    type: "select",
                    items: [
                        {key: "summary", value: "خلاصه"},
                        {key: "text", value: "متن کامل"}
                    ]
                },
                image: {title: "تصویر", type: "image"},
                text_selector: {title: "سلکتور"},
                text: {title: "متن"},
                html: {title: "HTML"},
                category_id: {
                    title: "دسته بندی",
                    type: "foreign",
                    foreign: {
                        module: "categories",
                        path: "/categories",
                        field: "id",
                        result: [{name: "name"}]
                    }
                }
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "source_id"},
                {name: "image"},
                {
                    name: "title",
                    type: "function",
                    value: {
                        func: props.link,
                        params: ["url", "title"]
                    }
                },
                {name: "category_id"},
                {
                    name: "text",
                    type: "function",
                    value: {
                        func: props.button,
                        params: ["text", {type: "static", value: "متن"}]
                    }
                },
                {
                    name: "html",
                    type: "function",
                    value: {
                        func: props.button,
                        params: ["html", {type: "static", value: "HTML"}]
                    }
                },
                {name: "date"},
                {name: "status"},
                {name: "create_date", title: "زمان دریافت", type: "timeago", alias: "cdate"}
            ],
            search: [
                {
                    component_type: "select",
                    type: "field",
                    name: "source_id",
                    value: "source_id",
                    field: "source_id",
                    source_data: props.sources,
                    placeholder: "منبع",
                    search_type: "exact",
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "title",
                    value: "title",
                    field: "title",
                    placeholder: "عنوان",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "summary",
                    value: "summary",
                    field: "summary",
                    placeholder: "خلاصه",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "text",
                    value: "text",
                    field: "text",
                    placeholder: "متن",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "select",
                    type: "field",
                    name: "status",
                    value: "status",
                    field: "status",
                    placeholder: "وضعیت",
                    search_type: "exact",
                    source_data: [
                        {key: "summary", value: "خلاصه"},
                        {key: "text", value: "متن کامل"}
                    ]
                },
                {
                    component_type: "select",
                    type: "field",
                    name: "category_id",
                    value: "category_id",
                    field: "category_id",
                    placeholder: "دسته بندی",
                    search_type: "exact",
                    source_data: props.categories
                },
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "image"},
                {name: "title"},
                {name: "category_id", type: "select_data"},
                {name: "url"},
                {name: "url_hash"},
                {name: "summary"},
                {name: "date"},
                {name: "status"},
                {name: "source_id"},
                {name: "source_url"},
                {name: "text_selector"},
                {name: "text"},
                {name: "html"},
            ]
        }
    };
};