import React, { Component } from 'react';
import List from "../components/List";
import Item from "../components/Item";
import entityModel from "../models/TestCrawl";
import xhr from "../components/xhr";
import {Modal, Button, Alert} from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

export default class TestCrawl extends Component {
    state = {
        entities: {},
        show: false,
        title: '',
        body: '',
        message: ''
    };

    componentDidMount(){
        this.setState({
            entities : entityModel({
                page: this.props.match.params.page,
                id: this.props.match.params.id,
                source: this.props.match.params.source,
                test: this.test
            })
        });
    }

    test = (args) => {
        console.log(args);
        let data = `news_link=${args.url}&source_id=${this.props.match.params.source}`;
        new xhr(this, 'test_crawl', data).GetManyPure(response => {
            console.log(response);
            if(response.status){
                // this.setState({body: response.data})
            }else{
                this.setState({message: response.note});
            }
        });
    };

    showModal = (title, body) => {
        this.setState({
            title,
            body: ReactHtmlParser(body),
            show: true
        });
    };

    hide = () => {
        this.setState({
            show: false,
            title: '',
            body: ''
        });
    };

    render(){
        let {entities, show, body, message} = this.state;
        let {pathname} = this.props.location;
        return (
            <div>
                {message && <Alert variant="danger">{message}</Alert>}
                <Modal show={show} onHide={this.hide}>
                    <Modal.Header closeButton>
                        <Modal.Title>پیش نمایش</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {body}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={this.hide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                {entities.base ?
                    pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                        <Item base={entities.base} data={entities.item}/>
                        :
                        <List base={entities.base} data={entities.list}/>
                    : ''}
            </div>
        );
    }
}