export default (props) => {
    return {
        base: {
            entities: "دسته بندی ها",
            entity: "دسته بندی",
            module: "categories",
            path: "/categories",
            model: {
                name: {title: "نام"},
                image: {title: "تصویر", type: "image"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "image"},
                {name: "name"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "image"},
                {name: "name"},
            ]
        }
    };
};