export default (props) => {
    return {
        base: {
            entities: "کشورها",
            entity: "کشور",
            module: "countries",
            path: "/countries",
            model: {
                name: {title: "نام"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "name"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "name"},
            ]
        }
    };
};